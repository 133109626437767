<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
// import { useUnleashStore } from '~/stores/unleash.store'

// const unleashStore = useUnleashStore()

const user = ref(null)
const { $post } = useApi()
const { login } = useAuth()
const email = ref('')
const fullname = ref('')
const password = ref('')
const isAuth = ref(false)
const isWrong = ref(false)
const passwordCopy = ref('')
const isPasswordHidden = ref(true)
const isPasswordCopyHidden = ref(true)
const isLoading = ref(false)
const isFocus = ref(false)
const isFocusCopy = ref(false)
const isPasswordValidate = helpers.regex(/^[A-Za-z0-9!@#$%^&*()-=_+{}\][\]|;:',.<>/?~]*$/)
const isEqualPassword = (value) => value === password.value
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const rulesRegister = reactive({
  email: {
    required: helpers.withMessage(() => 'Обязательное поле', required),
    minLength: helpers.withMessage(() => 'Минимальное количество символов 3', minLength(3)),
    validateEmail: helpers.withMessage(() => 'Введите корректный email', validateEmail)
  },
  fullname: {
    required: helpers.withMessage(() => 'Обязательное поле', required),
    minLength: helpers.withMessage(() => 'Минимальное количество символов 3', minLength(3))
  },
  password: {
    required: helpers.withMessage(() => 'Обязательное поле', required),
    minLength: helpers.withMessage(() => 'Минимальное количество символов 8', minLength(8)),
    isPasswordValidate: helpers.withMessage(
      () => 'Пароль должен состоять из латинских букв',
      isPasswordValidate
    )
  },

  passwordCopy: {
    required: helpers.withMessage(() => 'Обязательное поле', required),
    isEqualPassword: helpers.withMessage(() => 'Пароли должны совпадать', isEqualPassword)
  }
})

const rulesLogin = reactive({
  email: {
    required: helpers.withMessage(() => 'Обязательное поле', required),
    minLength: helpers.withMessage(() => 'Минимальное количество символов 3', minLength(3)),
    validateEmail: helpers.withMessage(() => 'Введите корректный email', validateEmail)
  },
  password: {
    required: helpers.withMessage(() => 'Обязательное поле', required),
    minLength: helpers.withMessage(() => 'Минимальное количество символов 3', minLength(3))
  }
})

const validatorRegister = useVuelidate(
  rulesRegister,
  { email, fullname, password, passwordCopy },
  { $rewardEarly: true }
)

const validatorLogin = useVuelidate(rulesLogin, { email, password }, { $rewardEarly: true })
const register = ref(null)
async function OnRegisterSubmit() {
  isLoading.value = true
  try {
    const isValid = await validatorRegister.value.$validate()
    if (!isValid) {
      isLoading.value = false
      return
    }
    await $post('/api/v1/auth/register', {
      name: fullname.value,
      email: email.value,
      password: password.value
    })
    user.value = await login(email.value, password.value)
    isLoading.value = false
    await navigateTo('/user')
  } catch (error) {
    isWrong.value = true
    isLoading.value = false
  }
}

async function OnLoginSubmit() {
  isLoading.value = true
  try {
    const isValid = await validatorLogin.value.$validate()
    if (!isValid) {
      isLoading.value = false
      return
    }
    user.value = await login(email.value, password.value)
    isLoading.value = false
    await navigateTo('/')
  } catch (error) {
    isWrong.value = true
    console.log(error)
    isLoading.value = false
  }
}

watch(password, () => {
  password.value = password.value.replace(' ', '')
})

watch(passwordCopy, () => {
  passwordCopy.value = passwordCopy.value.replace(' ', '')
})

watch(isAuth, () => {
  isLoading.value = false
  isWrong.value = false
  email.value = ''
  password.value = ''
  isPasswordHidden.value = true
  isPasswordCopyHidden.value = true
  isFocus.value = false
  isFocusCopy.value = false
})

function loginWithVk() {
  const { Connect, Config } = window.SuperAppKit
  Config.init({ appId: useRuntimeConfig().public.vk.appId })
  Connect.redirectAuth({ url: `${useRuntimeConfig().public.vk.redirectUrl}/auth/vk/verify` })
}
</script>

<template>
  <NuxtLayout name="main-shop-layout">
    <section v-if="isAuth" class="container my-20 px-4 xl:px-0">
      <div class="flex flex-col justify-center w-88 mx-auto">
        <div class="text-5xl flex justify-center font-semibold pb-7">Регистрация</div>
        <div class="relative flex flex-col space-y-3 pb-7">
          <div class="text-lg">Имя и фамилия</div>
          <t-input
            v-model="fullname"
            rounded="full"
            style-input="gray"
            placeholder="Никита Петров"
            height="large"
            :is-valid="!validatorRegister.fullname.$error"
          />
          <div
            v-for="(error, idx) in validatorRegister.fullname.$errors"
            :key="idx"
            class="absolute top-16 left-0"
          >
            <span class="text-decorative-scarlet text-xs">{{ error.$message }}</span>
          </div>
        </div>
        <div class="relative flex flex-col space-y-3 pb-7">
          <div class="text-lg">E-mail</div>
          <t-input
            v-model="email"
            rounded="full"
            style-input="gray"
            placeholder="name@example.com"
            height="large"
            :is-valid="!validatorRegister.email.$error"
          />

          <div
            v-for="(error, idx) in validatorRegister.email.$errors"
            :key="idx"
            class="absolute top-16 left-0"
          >
            <span v-if="idx === 0" class="text-decorative-scarlet text-xs">{{
              error.$message
            }}</span>
          </div>
        </div>
        <div class="relative flex flex-col space-y-3 pb-7">
          <div class="text-lg">Пароль</div>
          <t-input
            v-model="password"
            :type-input="isPasswordHidden ? 'password' : 'text'"
            rounded="full"
            style-input="gray"
            placeholder="********"
            height="large"
            :is-valid="!validatorRegister.password.$error"
            @focus="isFocus = true"
          >
            <template #suffix>
              <div v-if="isFocus && password.length" @click="isPasswordHidden = !isPasswordHidden">
                <t-icon-eye v-if="isPasswordHidden" class="w-6 h-6 fill-current" />
                <t-icon-eye-closed v-else class="w-6 h-6 fill-current" />
              </div>
            </template>
          </t-input>
          <div
            v-for="(error, idx) in validatorRegister.password.$errors"
            :key="idx"
            class="absolute top-16 left-0"
          >
            <span v-if="idx === 0" class="text-decorative-scarlet text-xs">{{
              error.$message
            }}</span>
          </div>
        </div>
        <div class="relative flex flex-col space-y-3 pb-7">
          <div class="text-lg">Подтвердите пароль</div>
          <t-input
            v-model="passwordCopy"
            :type-input="isPasswordCopyHidden ? 'password' : 'text'"
            rounded="full"
            style-input="gray"
            placeholder="********"
            height="large"
            :is-valid="!validatorRegister.passwordCopy.$error"
            @focus="isFocusCopy = true"
          >
            <template #suffix>
              <div
                v-if="isFocusCopy && passwordCopy.length"
                @click="isPasswordCopyHidden = !isPasswordCopyHidden"
              >
                <t-icon-eye v-if="isPasswordCopyHidden" class="w-6 h-6 fill-current" />
                <t-icon-eye-closed v-else class="w-6 h-6 fill-current" />
              </div>
            </template>
          </t-input>
          <div
            v-for="(error, idx) in validatorRegister.passwordCopy.$errors"
            :key="idx"
            class="absolute top-16 left-0"
          >
            <span v-if="idx === 0" class="text-decorative-scarlet text-xs">{{
              error.$message
            }}</span>
          </div>
        </div>
        <div v-if="isWrong" class="flex justify-center text-decorative-scarlet pb-4">
          Эта почта уже занята
        </div>
        <div class="flex justify-center pb-4">
          <t-button
            rounded="large"
            :is-loading="isLoading"
            :disabled="isLoading"
            @click="OnRegisterSubmit()"
          >
            Зарегистрироваться
          </t-button>
        </div>
        <div class="flex justify-center text-lg">
          Уже есть аккаунт?&nbsp;
          <span class="text-primary-100 cursor-pointer" @click="isAuth = !isAuth"> Войти </span>
        </div>
      </div>
    </section>

    <section v-else class="container my-20 px-4 xl:px-0">
      <!-- <div class="flex flex-col justify-center w-88 mx-auto">
        <div class="text-5xl flex justify-center font-semibold pb-7">Авторизация</div>
        <div
          class="relative flex flex-col space-y-3 pb-7"
          v-if="unleashStore.isAuthorizationEnable"
        >
          <div class="text-lg">E-mail</div>
          <t-input
            v-model="email"
            rounded="full"
            style-input="gray"
            placeholder="name@example.com"
            height="large"
            :is-valid="!validatorLogin.email.$error"
          />
          <div
            v-for="(error, idx) in validatorLogin.email.$errors"
            :key="idx"
            class="absolute top-16 left-0"
          >
            <span v-if="idx === 0" class="text-decorative-scarlet text-xs">{{
              error.$message
            }}</span>
          </div>
        </div>
        <div
          class="relative flex flex-col space-y-3 pb-7"
          v-if="unleashStore.isAuthorizationEnable"
        >
          <div class="text-lg">Пароль</div>

          <t-input
            v-model="password"
            :type-input="isPasswordHidden ? 'password' : 'text'"
            rounded="full"
            style-input="gray"
            placeholder="********"
            height="large"
            :is-valid="!validatorLogin.password.$error"
            @focus="isFocus = true"
          >
            <template #suffix>
              <div v-if="isFocus && password.length" @click="isPasswordHidden = !isPasswordHidden">
                <t-icon-eye v-if="isPasswordHidden" class="w-6 h-6 fill-current" />
                <t-icon-eye-closed v-else class="w-6 h-6 fill-current" />
              </div>
            </template>
          </t-input>
          <div
            v-for="(error, idx) in validatorLogin.password.$errors"
            :key="idx"
            class="absolute top-16 left-0"
          >
            <span v-if="idx === 0" class="text-decorative-scarlet text-xs">{{
              error.$message
            }}</span>
          </div>
        </div>
        <div v-if="isWrong" class="flex justify-center text-decorative-scarlet pb-4">
          Неправильный адрес эл. почты или пароль
        </div>
        <div class="flex flex-col justify-center pb-4" v-if="unleashStore.isAuthorizationEnable">
          <t-button
            rounded="large"
            class="text-primary cursor-pointer"
            :is-loading="isLoading"
            :disabled="isLoading"
            @click="OnLoginSubmit"
          >
            Войти
          </t-button>
        </div>
        <div class="flex flex-col justify-center items-center mt-8 mb-4">
          <div class="text-lg" v-if="unleashStore.isAuthorizationEnable">
            Или продолжить с аккаунтом
          </div>
          <div class="flex flex-row space-x-4 mt-4">
            <a href="#" v-if="unleashStore.isFacebookOAuthEnable"
              ><a-icon-facebook class="h-10 w-10" />
            </a>
            <a href="#" v-if="unleashStore.isGoogleOAuthEnable">
              <a-icon-instagram class="h-10 w-10" />
            </a>
            <button @click="loginWithVk">
              <a-icon-vk class="h-10 w-10" />
            </button>
          </div>
        </div>
        <div class="flex justify-center text-lg" v-if="unleashStore.isAuthorizationEnable">
          Нет аккаунта?&nbsp;
          <span class="text-primary-100 cursor-pointer" @click="isAuth = !isAuth">
            Зарегистрироваться
          </span>
        </div>
      </div> -->
    </section>
  </NuxtLayout>
</template>
